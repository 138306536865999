import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useStoreActions } from 'easy-peasy';
import { get } from 'lodash/fp';
import { emailVerification as emailVerificationPath } from '../../paths';
import { LOGIN, CONTACT } from '../../constants/UserEmailTypes';
import { requestEmailVerificationCode } from '../../services/userAuth';
import useCurrentUser from '../../hooks/useCurrentUser';
import history from '../../history';

const RequestCodeMessage =
  'Email verification code sent. Please check your email.';

const VerifyEmailButton = ({ label, type, ...props }) => {
  const user = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = useStoreActions(get('notifications'));

  const handleOnClick = async () => {
    setLoading(true);
    try {
      await requestEmailVerificationCode({ userId: user.id, type });
      await showSuccess({ message: RequestCodeMessage });
      history.push(`${emailVerificationPath}?type=${type}`);
    } catch ({ message }) {
      showError({ message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      {...props}
      onClick={handleOnClick}
      color="primary"
      disabled={loading}
      style={{
        top: -1,
        position: 'relative',
        padding: '1px 8px',
        textTransform: 'none'
      }}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : label}
    </Button>
  );
};

VerifyEmailButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([LOGIN, CONTACT])
};

VerifyEmailButton.defaultProps = {
  label: 'Please click here to verify your Email',
  type: LOGIN
};

export default VerifyEmailButton;
