/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import {
  createReservation,
  updateReservation,
  fetchReservation,
  updateReservationStatus,
  updateReservationUpdatedAt,
  deleteReservation
} from '../../services/fbm-api';

export default {
  data: null,
  loading: false,
  error: null,
  updatedStatus: false,
  updatingStatus: false,
  updateStatusError: null,
  deleting: false,
  deleted: false,
  deleteError: null,
  set: action((state, data) => {
    state.data = { ...(state.data || {}), ...data };
  }),
  clear: action(state => {
    state.data = null;
  }),
  create: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const reservation = await createReservation(payload);
      actions.clear();
      actions.set(reservation);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  update: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const { status } = await updateReservation(payload);
      actions.set({ status });
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  fetch: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const reservation = await fetchReservation(payload);
      actions.set(reservation);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  updateStatus: thunk(async (actions, payload) => {
    try {
      actions.setUpdatingStatus(true);
      const reservation = await updateReservationStatus(payload);
      await actions.set(reservation);
      actions.setUpdatedStatus(true);
      return reservation;
    } catch (error) {
      actions.setUpdateStatusError(error);
      return error;
    } finally {
      actions.setUpdatedStatus(false);
      actions.setUpdatingStatus(false);
    }
  }),
  updateUpdatedAt: thunk(async (actions, payload) => {
    try {
      await updateReservationUpdatedAt(payload);
    } catch (error) {
      actions.setError(error);
    }
  }),
  delete: thunk(async (actions, payload) => {
    try {
      actions.setDeleting(true);
      await deleteReservation(payload);
    } catch (error) {
      actions.setDeleteError(error);
    } finally {
      actions.clear();
      actions.setDeleting(false);
      actions.setDeleted(true);
    }
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setError: action((state, error) => {
    state.error = error;
  }),
  clearError: action(state => {
    state.error = null;
  }),
  setUpdatedStatus: action((state, updatedStatus) => {
    state.updatedStatus = updatedStatus;
  }),
  setUpdatingStatus: action((state, updatingStatus) => {
    state.updatingStatus = updatingStatus;
  }),
  setUpdateStatusError: action((state, error) => {
    state.updateStatusError = error;
  }),
  clearUpdateStatusError: action(state => {
    state.updateStatusError = null;
  }),
  setDeleted: action((state, deleted) => {
    state.deleted = deleted;
  }),
  setDeleting: action((state, deleting) => {
    state.deleting = deleting;
  }),
  setDeleteError: action((state, error) => {
    state.deleteError = error;
  }),
  clearDeleteError: action(state => {
    state.deleteError = null;
  })
};
