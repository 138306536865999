import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import {
  has,
  set,
  get,
  getOr,
  some,
  map,
  size,
  flow,
  filter,
  forOwn,
  isEmpty,
  sortBy,
  isNull,
  values,
  reduce,
  flatten,
  includes,
  zipObject
} from 'lodash/fp';
import { Box, Button, CircularProgress } from '@material-ui/core';
import ContinueIcon from '@material-ui/icons/NavigateNext';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  login as loginPath,
  guestEditProfile as guestProfilePath,
  reservationSummary as reservationSummaryPath
} from '../../paths';
import HealthLevels from '../../constants/HealthLevels';
import pagePathToUrl from '../../helpers/pagePathToUrl';
import history from '../../history';
import Layout from '../../components/Layout';
import PageLoading from '../../components/PageLoading';
import StepHeader from '../../components/ReservationStepHeader';
import ProgramsPicklists from './ProgramsPicklists';
import useCurrentUser from '../../hooks/useCurrentUser';
import HealthLevelIndicator from '../../components/HealthLevelIndicator';
import HealthIndicatorLabel from './HealthIndicatorLabel';
import toOrdersByPicklist from '../../helpers/toOrdersByPicklist';
import { ON_HOLD, PENDING } from '../../constants/ReservationStatus';
import STATUS_ACTIONS from '../../constants/ReservationStatusActions';
import excludeZeroLimit from '../../helpers/excludeZeroLimit';
import getItemAvailabilityUniqKey from '../../helpers/inventory/getItemAvailabilityUniqKey';

const someWithKey = some.convert({ cap: false });

const getItemsInventorySnapshotFromOrders = orders =>
  flow(
    reduce((allItems, each) => [...allItems, ...each.items], []),
    reduce((all, { inventorySnapshot }) => {
      if (!inventorySnapshot) return all;
      const uniqKey = getItemAvailabilityUniqKey(inventorySnapshot);
      return set(uniqKey, inventorySnapshot, all);
    }, {})
  )(orders);

const ordersByPicklistToBody = ordersByPicklist =>
  flow(
    values,
    filter(({ items }) => !isEmpty(items))
  )(ordersByPicklist);

const getItemsWithAvailabilityError = (body, formError) =>
  reduce(
    (result, { field, message: fieldError }) =>
      field.includes('.quantity') && fieldError.includes('available quantity')
        ? [...result, get(field.replace('.quantity', ''), body)]
        : result,
    [],
    formError
  );

export default function Pantry() {
  const { pantryPath, id } = useParams();
  const [ordersByPicklist, setOrdersByPicklist] = useState({});
  const [hasReservationOrders, setHasReservationOrders] = useState(false);
  const currentUser = useCurrentUser();
  const userDetails = useStoreState(get('userDetails.data'));
  const guestInfo = get('otherInfo', userDetails);
  const { data: reservation, loading } = useStoreState(get('reservation'));

  const requestTime = reservation && moment(reservation.requestTime);
  const user = useStoreState(get('user.data'));
  const isUserVerified = !!get('verifiedAt', user);
  const pantry = useStoreState(get('pantry.data'));
  const picklists = useStoreState(get('reservationPicklists.visiblePicklists'));
  const loadingPicklists = useStoreState(get('reservationPicklists.loading'));
  const savingOrders = useStoreState(get('reservationOrder.loading'));
  const ordersError = useStoreState(get('reservationOrder.error'));
  const ordersSaved = !isEmpty(useStoreState(get('reservationOrder.data')));
  const picklistsError = useStoreState(get('reservationPicklists.error'));

  const {
    itemsSumQuantityByUniqKey,
    itemsInitialSumQuantityByUniqKey: itemsInitialSumQtyByUniqKey
  } = useStoreState(get('pages.reservationOrder'));
  const {
    data: itemsAvailabilityByUniqKey,
    loading: loadingItemAvailability
  } = useStoreState(get('inventory.itemsAvailabilityByUniqKey'));

  const showError = useStoreActions(get('notifications.showError'));
  const showSuccess = useStoreActions(get('notifications.showSuccess'));
  const fetchPantry = useStoreActions(get('pantry.fetch'));
  const clearPantry = useStoreActions(get('pantry.clear'));
  const fetchReservation = useStoreActions(get('reservation.fetch'));
  const clearReservation = useStoreActions(get('reservation.clear'));
  const updateReservationUpdatedAt = useStoreActions(
    get('reservation.updateUpdatedAt')
  );

  const createOrder = useStoreActions(get('reservationOrder.create'));
  const updateOrder = useStoreActions(get('reservationOrder.update'));
  const clearOrder = useStoreActions(get('reservationOrder.clear'));
  const clearOrderError = useStoreActions(get('reservationOrder.clearError'));
  const fetchPicklists = useStoreActions(get('reservationPicklists.fetch'));
  const clearPicklists = useStoreActions(get('reservationPicklists.clear'));
  const fetchItemAvailability = useStoreActions(
    get('inventory.itemsAvailabilityByUniqKey.fetch')
  );
  const {
    clearItemsSumQuantityForOrders,
    updateItemsSumQuantityForOrders,
    clearItemsInitialSumQuantityForOrders,
    updateInitialItemsSumQuantityForOrders
  } = useStoreActions(get('pages.reservationOrder'));
  const updateStatus = useStoreActions(get('reservation.updateStatus'));
  const status = get('status', reservation);
  const event = get('reservation.outreach', reservation);
  const eventDate = get('date', event);
  const eventEndTime = get('endTime', event);
  const hasOrderItems = useMemo(
    () =>
      reduce((sum, { items }) => sum + size(items), 0, ordersByPicklist) > 0,
    [ordersByPicklist]
  );

  const sortedPicklists = useMemo(
    () =>
      flow(
        values,
        list => excludeZeroLimit(guestInfo, list),
        sortBy(['position', 'name'])
      )(picklists),
    [picklists]
  );

  const healthLevelChoices = useMemo(() => {
    const choices = flow(
      map('items'),
      flatten,
      map('healthLevel')
    )(sortedPicklists);
    return zipObject(choices, choices);
  }, [sortedPicklists]);

  const hasExceedInventoryLimit = useMemo(() => {
    if (isEmpty(itemsAvailabilityByUniqKey)) return false;

    return someWithKey((itemSumQuantity, key) => {
      const itemAvailability = get(key, itemsAvailabilityByUniqKey);
      if (itemAvailability) {
        const { quantity, onholdQuantity } = itemAvailability;
        const initialSumQuantity = getOr(0, key, itemsInitialSumQtyByUniqKey);
        return itemSumQuantity - initialSumQuantity > quantity - onholdQuantity;
      }
      return false;
    }, itemsSumQuantityByUniqKey);
  }, [
    itemsAvailabilityByUniqKey,
    itemsSumQuantityByUniqKey,
    itemsInitialSumQtyByUniqKey
  ]);

  const dateTimeToCompare =
    get('scheduleMinutesInterval', event) === 0
      ? moment(`${eventDate} ${eventEndTime}`)
      : requestTime;

  const fetchOrderItemsAvailability = inventorySnapshots => {
    forOwn(inventorySnapshot => {
      const itemId = get('item.id', inventorySnapshot);
      const locationId = get('location.id', inventorySnapshot);
      const zoneId = get('zone.id', inventorySnapshot);
      fetchItemAvailability({ pantryPath, itemId, locationId, zoneId });
    }, inventorySnapshots);
  };

  const forwardToReservationSummary = () => {
    history.replace(pagePathToUrl(reservationSummaryPath, { pantryPath, id }));
  };

  const handleChangeOrders = newOrdersByPicklist => {
    setOrdersByPicklist(newOrdersByPicklist);
    updateReservationUpdatedAt({ pantryPath, id });
    updateItemsSumQuantityForOrders(newOrdersByPicklist);
  };

  const handleContinue = async () => {
    const body = ordersByPicklistToBody(ordersByPicklist);

    if (!hasReservationOrders) await createOrder({ pantryPath, id, body });
    else await updateOrder({ pantryPath, id, body });
  };

  useEffect(() => {
    fetchReservation({ pantryPath, id });
    return () => {
      clearOrder();
      clearPantry();
      clearPicklists();
      clearReservation();
      clearItemsSumQuantityForOrders();
      clearItemsInitialSumQuantityForOrders();
      setOrdersByPicklist({});
      clearOrderError();
    };
  }, []);

  useEffect(() => {
    if (userDetails && !get('otherInfo.householdTotal', userDetails)) {
      history.push(
        `${guestProfilePath}?pantryPath=${pantryPath}&reservationId=${id}`
      );
    }
  }, [userDetails]);

  useEffect(() => {
    if (isNull(picklists)) {
      fetchPicklists({ pantryPath, id });
    } else if (isEmpty(picklists)) forwardToReservationSummary();
  }, [picklists]);

  useEffect(() => {
    if (isUserVerified && !pantry) fetchPantry(pantryPath);
  }, [pantry, pantryPath, fetchPantry, isUserVerified]);

  useEffect(() => {
    if (!reservation) return;

    const { orders } = reservation;

    const isEditable = includes(reservation.status, [ON_HOLD, PENDING]);
    if (!isEditable) {
      showError({ message: 'Orders are no longer editable.' });
      forwardToReservationSummary();
    } else if (!isEmpty(orders)) {
      setHasReservationOrders(true);
      updateItemsSumQuantityForOrders(orders);
      updateInitialItemsSumQuantityForOrders(orders);
      setOrdersByPicklist(toOrdersByPicklist(orders));
      fetchOrderItemsAvailability(getItemsInventorySnapshotFromOrders(orders));
    }
  }, [reservation]);

  useEffect(() => {
    if (isEmpty(ordersError)) return;

    const { message, error: formError } = ordersError;
    if (!isEmpty(formError)) {
      const body = ordersByPicklistToBody(ordersByPicklist);
      const itemsWithError = getItemsWithAvailabilityError(body, formError);
      if (!isEmpty(itemsWithError)) {
        fetchOrderItemsAvailability(map('inventorySnapshot', itemsWithError));
      }
    } else {
      showError({ message });
    }
  }, [showError, ordersError]);

  useEffect(() => {
    if (isEmpty(picklistsError)) return;
    showError({ message: picklistsError.message });
  }, [showError, picklistsError]);

  useEffect(() => {
    const onOrderSaved = async () => {
      if (ON_HOLD === status) {
        if (dateTimeToCompare.isBefore(moment())) {
          await showError({
            message:
              "We're sorry! This reservation time slot has already closed."
          });
        } else {
          await updateStatus({
            id,
            pantryPath,
            statusAction: STATUS_ACTIONS.PENDING
          });
        }
      } else {
        await showSuccess({
          message: `Reservation orders have been updated!`
        });
        history.push(pagePathToUrl(reservationSummaryPath, { pantryPath, id }));
      }
    };

    if (ordersSaved) onOrderSaved();
  }, [ordersSaved]);

  if (!currentUser) {
    return <Redirect to={loginPath} />;
  }

  return (
    <Layout>
      {loading || loadingPicklists ? (
        <PageLoading />
      ) : (
        <>
          <StepHeader label="Step 3: Choose your items" />
          <Box textAlign={{ xs: 'center', sm: 'right' }}>
            {has(HealthLevels.GO, healthLevelChoices) && (
              <HealthIndicatorLabel component="span" variant="subtitle2">
                <HealthLevelIndicator value={HealthLevels.GO} /> GO (ANYTIME
                CHOICE) &nbsp; &nbsp;
              </HealthIndicatorLabel>
            )}
            {has(HealthLevels.SLOW, healthLevelChoices) && (
              <HealthIndicatorLabel component="span" variant="subtitle2">
                <HealthLevelIndicator value={HealthLevels.SLOW} /> SLOW
                (SOMETIME CHOICE) &nbsp; &nbsp;
              </HealthIndicatorLabel>
            )}
            {has(HealthLevels.WHOA, healthLevelChoices) && (
              <HealthIndicatorLabel component="span" variant="subtitle2">
                <HealthLevelIndicator value={HealthLevels.WHOA} />
                &nbsp;WHOA! (RARELY CHOICE)
              </HealthIndicatorLabel>
            )}
          </Box>
          <ProgramsPicklists
            orders={ordersByPicklist}
            picklists={sortedPicklists || []}
            onChangeOrders={handleChangeOrders}
          />
          {hasExceedInventoryLimit && (
            <Alert severity="error" style={{ marginBottom: 16 }}>
              <AlertTitle>Oops!</AlertTitle>
              There are errors in this order. Please check your ordered items
              and adjust accordingly. Once corrected you will be able to save
              the order.
            </Alert>
          )}
          {requestTime && (
            <Box textAlign="right">
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleContinue}
                endIcon={<ContinueIcon />}
                disabled={
                  savingOrders ||
                  loadingItemAvailability ||
                  !hasOrderItems ||
                  hasExceedInventoryLimit
                }
              >
                {savingOrders ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  `${
                    hasReservationOrders
                      ? 'Continue'
                      : 'Agree and Confirm Reservation'
                  }`
                )}
              </Button>
            </Box>
          )}
        </>
      )}
    </Layout>
  );
}
