import React, { useEffect } from 'react';
import { get } from 'lodash/fp';
import Box from '@material-ui/core/Box';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Dialog from '../Dialog';
import LoginForm from '../LoginForm';
import SignupForm from '../SignupForm';
import FormDivider from '../Divider';
import Copyright from '../Copyright';

function LoginSignupDialog(props) {
  const user = useStoreState(get('user.data'));
  const { showDialog, showLoginForm, showSignupForm } = useStoreState(
    get('components.loginSignupDialog')
  );
  const {
    setShowDialog,
    setShowLoginForm,
    setShowSignupForm
  } = useStoreActions(get('components.loginSignupDialog'));

  const handleOnCloseDialog = () => {
    setShowDialog(false);
    setShowLoginForm(false);
    setShowSignupForm(false);
  };

  useEffect(() => {
    return () => {
      setShowDialog(false);
      setShowLoginForm(false);
      setShowSignupForm(false);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setShowDialog(false);
      setShowLoginForm(false);
      setShowSignupForm(false);
    }
  }, [user]);

  return (
    <Dialog open={showDialog} onClose={handleOnCloseDialog} {...props}>
      <Box mx="auto" px={{ xs: 1, sm: 3 }} maxWidth={{ xs: 318, sm: 350 }}>
        {showLoginForm && <LoginForm />}
        {showLoginForm && showSignupForm && (
          <Box my={2}>
            <FormDivider data-content="OR" />
          </Box>
        )}
        {showSignupForm && <SignupForm showLogin={!showLoginForm} />}
      </Box>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Dialog>
  );
}

export default LoginSignupDialog;
